@import './index.less';


.ant-layout-sider-collapsed {
  min-width: 0 !important;
  max-width: 0 !important;
}

.ant-drawer-body {
  padding: 0;
}

#main {
  height: 100%;

  .layout {
    height: 100%;
  }

  .logo {
    margin: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    flex-direction: column;
    .logoImg {
      height: 60px;
      margin-top: 10px;
    }

    .logoNameA{
      background-color: #fff;
      font-weight: 900;
      font-size: 50;
      color: white;
      background-color: #263263;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }

  }

 
 

  .sider {
    overflow: hidden;
    background-color: #65b919;

  }

  .drawer {}

  

  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  .menu {
    background-color: #4e9113;

    background-color: #65b919;


  }

  .subMenu {
    background-color: #65b919;
  }

  .subMenuItem {
    background: #4a7d1c;


  }

  .ant-menu-inline.ant-menu-sub {
    background: #4a7d1c;

  }


}

.signup-form-detail-description-container {
  .ant-descriptions {
    margin-bottom: 16px;
  }

  .ant-descriptions-item-label {
    width: 25%;
  }

  .signup-form-detail-image {
    border: 1px solid #d9d9d9;
    padding: 4px;
    cursor: pointer;
    transition: border .15s;
    margin-right: 4px;
    margin-bottom: 4px;

    &:hover {
      border-color: #81c73c;
    }

    > .ant-image-img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}

.ant-alert-info {
  background: #e6f7ff;
  border-color: #91d5ff;

  .ant-alert-icon {
    color: #1890ff;
  }
}
